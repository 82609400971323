/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/fullpage.js@2.9.4/dist/jquery.fullpage.min.js
 * - /npm/malihu-custom-scrollbar-plugin@3.1.5/jquery.mCustomScrollbar.min.js
 * - /npm/slick-carousel@1.8.1/slick/slick.min.js
 * - /npm/modal-video@2.4.8/js/jquery-modal-video.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
